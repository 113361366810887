<template>
  <FormSquadre></FormSquadre>
</template>

<script>
import FormSquadre from "../components/FormSquadre.vue";

export default {
  name: "GestioneSquadre",
  components: {
    FormSquadre
  },
  data(){
    return {
        
    }
},
methods: {

},
};
</script>