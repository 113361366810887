<template>
  <UploadListone></UploadListone>
</template>

<script>
import UploadListone from "@/components/UploadListone";
export default {
  name: "Upload-Listone",
  components: {
    UploadListone
  },
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>