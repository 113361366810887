<template>
<ImpostazioniGenerali />
</template>
<script>
import ImpostazioniGenerali from "../components/ImpostazioniGenerali.vue";
import axios from "axios";

export default {
  name: "Impostazioni",
  components: {
    ImpostazioniGenerali,
  },
  data(){
    return {
        
    }
},
methods: {

},
};
</script>


